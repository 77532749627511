"use strict";
// We use those fallbacks to make cssToRN not depend on react-native
Object.defineProperty(exports, "__esModule", { value: true });
exports.PixelRatio = exports.Dimensions = exports.Platform = void 0;
exports.Platform = {
    OS: 'web'
};
exports.Dimensions = {
    get: (dimension) => dimension === 'width' ? window.innerWidth : window.innerHeight
};
exports.PixelRatio = {
    getPixelSizeForLayoutSize: (dp) => dp * window.devicePixelRatio
};
